import classNames from 'classnames';
import { useCallback, useEffect, useRef, forwardRef, useImperativeHandle, useContext, useMemo } from 'react';
import DruidWebchatContext, { DruidWebchatContextType } from './WebChatContext';
import { determineDirection, getSideRTLAware } from './utils/localeUtils';
import  { useAutoExpand }from './hooks/useAutoExpand';
import  { useContainerElementToggle } from './hooks/useContainerElementToggle';

import { WebChatOptions, webChatDefaultOptions as defaultOptions } from './WebChatOptions';

import loadable from '@loadable/component';


// import WebChat from './WebChat';
import './MinimizableWebChat.scss';

// import '../fabric-icons-inline.css';
import { useDeterminedDirection } from './selectors/useDeterminedDirection';
import { mobileAndTabletcheck } from './utils/mobileAndTabletCheck';
import useStyleOptions from 'botframework-webchat/lib/hooks/useStyleOptions';
import { useMergedOptions } from './selectors/useMergedOptions';
import InlineSVG from 'svg-inline-react';
import FocusTrap from 'focus-trap-react';
// import { DruidAction } from './hooks/useWebChatReducer';

const LoadableWebchat =  loadable(() => {
    return import(
      /* webpackChunkName: "modules" */ 
      './WebChat');
 });


const MinimizableWebChat = forwardRef(({initialOptions}: {initialOptions: Partial<WebChatOptions>}, ref) => {

  const [druidWebChatState, druidWebChatDispatch,, druidStyleToEmotionObject] = useContext<DruidWebchatContextType>(DruidWebchatContext);

  const { styleOptions : { accent, subtle, sendBoxButtonColorOnDisabled, sendBoxButtonColorOnHover, sendBoxButtonColorOnFocus, bubbleTextColor, paddingRegular, primaryFont, backgroundColor } = {},
         disabled,
         UI: { 
           top, bottom, height, width, minWidth, chatBoxBorderRadius, chatBoxBottomRadius, chatBoxBorderColor, chatBoxBorderSize,
           transcriptFontSize, headerTextColor, closeButtonEnabled, floatRight, resizableEnabled,
           containerElement
         } = {} 
        } = useMergedOptions();

  const determinedDir = useDeterminedDirection();

  const webchatLoadedRef = useRef<boolean>(false);

  useAutoExpand();

  useContainerElementToggle({initialOptions});

  useEffect(() => {
    containerElement
    && druidWebChatState.loaded
    && containerElement instanceof HTMLElement
    // && druidWebChatState.mergedChatOptions.UI?.closeButtonEnabled 
    && (containerElement.style.display = druidWebChatState.containerElementVisible ? 'initial': 'none');
  }, [druidWebChatState.containerElementVisible, containerElement, closeButtonEnabled, druidWebChatState.loaded]);

  const calculatedBottom =  `calc(${bottom}px + 64px + ${paddingRegular}px)`;
  const ROOT_STYLE = {
    '&.druid__minimizable__web-chat': {
        zIndex: 1051,
        fontFamily: primaryFont,
      
        '& .druid__minimizable__web-chat__chat-box': {
          backgroundColor: druidWebChatState.INITIALIZATION_STATUS === 'success' ? 'transparent': backgroundColor,
          border: chatBoxBorderSize,
          borderStyle: 'solid',
          borderColor: chatBoxBorderColor,
          borderRadius: chatBoxBorderRadius,
          borderBottomLeftRadius: chatBoxBottomRadius === 0 ? 0 : chatBoxBottomRadius || chatBoxBorderRadius,
          borderBottomRightRadius: chatBoxBottomRadius === 0 ? 0 : chatBoxBottomRadius || chatBoxBorderRadius,
          boxShadow: '0 0 5px rgba(0, 0, 0, .2)',
          backdropFilter: 'blur(10)',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          overflow: 'hidden',
          resize: !druidWebChatState.isMobile && !!resizableEnabled && 'horizontal',
          height:  closeButtonEnabled ? (typeof height  === 'number' ? height - 64 - paddingRegular!: `calc(${height} - ${calculatedBottom})`): height,
          width: width,
          minWidth: minWidth,
          maxWidth: `calc(100vw - ${typeof floatRight === 'number' ? floatRight + 'px' : floatRight})`,
          bottom: closeButtonEnabled && typeof bottom === 'number' ?  calculatedBottom: bottom,
          top: top,
          animationName: 'fadeIn',
          animationDuration: '1s',
          '&.hide':  {
            display: 'none'
          }, 
          '&.left': {
            left: floatRight
          },
          '&.right': {
            right: floatRight
          },
          '& .druid__web-chat__chat-container': {
            fontSize: transcriptFontSize,
            direction: determinedDir
          }
        },
        '& .druid__minimizable__web-chat__default-container-element': {
          backgroundColor: accent,
          borderRadius: '50%',
          border: `2px solid ${accent}`,
          bottom: 20,
          boxShadow: '0 0 20px rgba(0, 0, 0, .2)',
          color: headerTextColor,
          fontSize: 30,
          height: 64,
          lineHeight: 1,
          // outline: 0,
          padding: 15,
          position: 'fixed',
          right: getSideRTLAware(determinedDir, druidWebChatState.side) === 'right' && floatRight,
          left: getSideRTLAware(determinedDir, druidWebChatState.side) === 'left' && floatRight,
          width: 64,
          cursor: 'pointer',
          '& svg': {
            verticalAlign: 'unset'
          }
        },
    }
  };

  const rootClassName = druidStyleToEmotionObject(ROOT_STYLE) + '';



  // TODO: [P2] Currently, we cannot unmount Web Chat from DOM when it is minimized.
  //       Today, if we unmount it, Web Chat will call disconnect on DirectLineJS object.
  //       When minimized, we still want to maintain that connection while the UI is gone.
  //       This is related to https://github.com/microsoft/BotFramework-WebChat/issues/2750.

  return (
   

    <div className={classNames("druid__minimizable__web-chat",  rootClassName + '', druidWebChatState.isMobile && 'druid-is-mobile')}>
      {(!druidWebChatState.minimized || (druidWebChatState.initialized && druidWebChatState.mergedChatOptions.backgroundTrafficEnabled)) && (
        <FocusTrap active={!druidWebChatState.minimized && webchatLoadedRef?.current && !druidWebChatState.isMobile} focusTrapOptions={{delayInitialFocus: false, allowOutsideClick: true, clickOutsideDeactivates: true}}  >
          {/* containerElements={[document.querySelector(".druid__minimizable__web-chat")!]} */}
          
          <div className={classNames('druid__minimizable__web-chat__chat-box', getSideRTLAware(determinedDir, druidWebChatState.side) === 'left' ? 'left' : 'right', druidWebChatState.minimized ? 'hide' : '')} dir={getSideRTLAware(determinedDir, druidWebChatState.side) === 'left' ? 'ltr' : 'rtl'}>
            <LoadableWebchat
                ref={webchatLoadedRef}
                className="druid__web-chat__chat-container"
                initialOptions={initialOptions}
              />
          </div>
        </FocusTrap>

      )}

      {
        druidWebChatState.minimized && druidWebChatState.loaded && !containerElement &&
        <ButtonToggleWebchat>
          {/* <svg fill="currentColor" viewBox="0 0 16 16">
            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"></path>
          </svg> */}
        </ButtonToggleWebchat>
      }
      {
        !druidWebChatState.minimized && druidWebChatState.loaded && (closeButtonEnabled && !druidWebChatState.isMobile) &&
        <ButtonToggleWebchat>
          {/* <svg stroke="currentColor" fill="currentColor"  viewBox="0 0 16 16">
            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"></path>
          </svg> */}
        </ButtonToggleWebchat>
      }
      
    </div>
  
  );
});

MinimizableWebChat.defaultProps = { initialOptions: defaultOptions };
// MinimizableWebChat.displayName = "MinimizableWebChat";


const defaultExpandIcon = ({size}: {size: any}) => {
  return ` <svg fill="currentColor" viewBox="0 0 16 16">
  <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"></path>
</svg>`;
};
const defaultCollapseIcon = ({size}: {size: any}) => {
  return  `<svg stroke="currentColor" fill="currentColor"  viewBox="0 0 16 16">
  <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"></path>
</svg>`;
};
const ButtonToggleWebchat = ({children}) => {
  const [druidWebChatState, druidWebChatDispatch,,] = useContext<DruidWebchatContextType>(DruidWebchatContext);
  const { UI: { v2_containerElement_expand_icon = "", v2_containerElement_collapse_icon = "" } = {},
      styleOptions: { } = {}
    } = useMergedOptions();

  return  (<button className="druid__minimizable__web-chat__default-container-element" onClick={() => druidWebChatDispatch({ type: druidWebChatState.minimized ? 'OPEN_WEBCHAT': 'CLOSE_WEBCHAT' })}>
      { druidWebChatState.minimized && <InlineSVG src={typeof v2_containerElement_expand_icon === "function" ? v2_containerElement_expand_icon({size: 30}): (v2_containerElement_expand_icon || defaultExpandIcon({size: 30}))} raw={true} ></InlineSVG> }
      { !druidWebChatState.minimized && <InlineSVG src={typeof v2_containerElement_collapse_icon === "function" ? v2_containerElement_collapse_icon({size: 30}): (v2_containerElement_collapse_icon || defaultCollapseIcon({size: 30}))} raw={true} ></InlineSVG>}
      {druidWebChatState.newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
  </button>);
}

export default MinimizableWebChat;
