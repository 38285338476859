import moment from "moment";
import { WebChatState } from "../WebChatContext";

export function druidLanguageComparison(firstLocale: string, secondLocale: string) {
    return firstLocale.toLocaleLowerCase().startsWith(secondLocale.toLocaleLowerCase()) ||  secondLocale.toLocaleLowerCase().startsWith(firstLocale.toLocaleLowerCase());
}

export function isNewActivity(currentState: WebChatState, activity: any): boolean {
    return (currentState.directlineInstance?.serverTimestamp || moment()) < moment(activity.timestamp);
}

export function shouldEnter_sensitiveDataInputMode(activity: any): boolean {
    // activity.text === 'prompt test' || 
    return activity.inputHint === 'sensitiveDataInput';

}

export function shouldEnter_fileUploadInputMode(activity: any): boolean {
    // activity.text === 'prompt test' || 
    return activity?.channelData?.flowStepType === 'U';

}

export const AppConsts = { DefaultLanguageId: -1 };