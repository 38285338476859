// In order to keep file size down, only import the parts of rxjs that we use

import ReconnectingWebSocket from 'reconnecting-websocket';
import { AjaxRequest, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { MarkMessageStatusInput, ConversationStatus, MarkSenderActionInput, SenderActionAction, SenderAction, MessageStatusInputDto } from './shared/service-proxies/service-proxies'
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/count';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/take';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/bufferTime';
import 'rxjs/add/operator/buffer';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';


import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';

import moment, { Moment } from 'moment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { Stopwatch } from "ts-stopwatch";
import { buffer } from 'rxjs/operator/buffer';

const DIRECT_LINE_VERSION = 'DirectLine/3.0';

declare var process: {
    arch: string;
    env: {
        VERSION: string;
    };
    platform: string;
    release: string;
    version: string;
};

//DruidSoft proxy type

export { SenderActionAction } from './shared/service-proxies/service-proxies';

// Direct Line 3.0 types

export interface Conversation {
    conversationId: string,
    token: string,
    eTag?: string,
    streamUrl?: string,
    referenceGrammarId?: string
}

export type MediaType = "image/png" | "image/jpg" | "image/jpeg" | "image/gif" | "image/svg+xml" | "audio/mpeg" | "audio/mp4" | "video/mp4" | "video/webm";

export interface Media {
    contentType: MediaType,
    contentUrl: string,
    name?: string,
    thumbnailUrl?: string
}

export interface UnknownMedia {
    contentType: string,
    contentUrl: string,
    name?: string,
    thumbnailUrl?: string
}

export type CardActionTypes = "call" | "downloadFile" | "imBack" | "messageBack" | "openUrl" | "playAudio" | "playVideo" | "postBack" | "signin" | "showImage";

export type CardAction = CallCardAction | DownloadFileCardAction | IMBackCardAction | MessageBackCardAction | OpenURLCardAction | PlayAudioCardAction | PlayVideoCardAction | PostBackCardAction | SignInCardAction | ShowImageCardAction;

export interface CallCardAction {
    image?: string,
    title: string,
    type: "call",
    value: any
}

export interface DownloadFileCardAction {
    image?: string,
    title: string,
    type: "downloadFile",
    value: any
}

export interface IMBackCardAction {
    image?: string,
    title?: string,
    type: "imBack",
    value: string,
    channelData: any // DRUID CUSTOMIZATION
}

export type MessageBackCardAction = MessageBackWithImage | MessageBackWithTitle

export interface MessageBackWithImage {
    displayText?: string,
    image: string,
    text?: string,
    title?: string,
    type: "messageBack",
    value?: any
}

export interface MessageBackWithTitle {
    displayText?: string,
    image?: string,
    text?: string,
    title: string,
    type: "messageBack",
    value?: any
}

export interface OpenURLCardAction {
    image?: string,
    title: string,
    type: "openUrl",
    value: any
}

export interface PlayAudioCardAction {
    image?: string,
    title: string,
    type: "playAudio",
    value: any
}

export interface PlayVideoCardAction {
    image?: string,
    title: string,
    type: "playVideo",
    value: any
}

export interface PostBackCardAction {
    image?: string,
    title?: string,
    type: "postBack",
    value: any,
    channelData: any // DRUID CUSTOMIZATION
}

export interface ShowImageCardAction {
    image?: string,
    title: string,
    type: "showImage",
    value: any
}

export interface SignInCardAction {
    image?: string,
    title: string,
    type: "signin",
    value: any
}

export interface CardImage {
    alt?: string,
    url: string,
    tap?: CardAction
}

export interface HeroCard {
    contentType: "application/vnd.microsoft.card.hero",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        images?: CardImage[],
        buttons?: CardAction[],
        tap?: CardAction
    }
}

export interface Thumbnail {
    contentType: "application/vnd.microsoft.card.thumbnail",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        images?: CardImage[],
        buttons?: CardAction[],
        tap?: CardAction
    }
}

export interface Signin {
    contentType: "application/vnd.microsoft.card.signin",
    content: {
        text?: string,
        buttons?: CardAction[]
    }
}

export interface OAuth {
    contentType: "application/vnd.microsoft.card.oauth",
    content: {
        text?: string,
        connectionname: string,
        buttons?: CardAction[]
    }
}

export interface ReceiptItem {
    title?: string,
    subtitle?: string,
    text?: string,
    image?: CardImage,
    price?: string,
    quantity?: string,
    tap?: CardAction
}

export interface Receipt {
    contentType: "application/vnd.microsoft.card.receipt",
    content: {
        title?: string,
        facts?: { key: string, value: string }[],
        items?: ReceiptItem[],
        tap?: CardAction,
        tax?: string,
        vat?: string,
        total?: string,
        buttons?: CardAction[]
    }
}

// Deprecated format for Skype channels. For testing legacy bots in Emulator only.
export interface FlexCard {
    contentType: "application/vnd.microsoft.card.flex",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        images?: CardImage[],
        buttons?: CardAction[],
        aspect?: string
    }
}

export interface AudioCard {
    contentType: "application/vnd.microsoft.card.audio",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        media?: { url: string, profile?: string }[],
        buttons?: CardAction[],
        autoloop?: boolean,
        autostart?: boolean
    }
}

export interface VideoCard {
    contentType: "application/vnd.microsoft.card.video",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        media?: { url: string, profile?: string }[],
        buttons?: CardAction[],
        image?: { url: string, alt?: string },
        autoloop?: boolean,
        autostart?: boolean
    }
}

export interface AdaptiveCard {
    contentType: "application/vnd.microsoft.card.adaptive",
    content: any;
}

export interface AnimationCard {
    contentType: "application/vnd.microsoft.card.animation",
    content: {
        title?: string,
        subtitle?: string,
        text?: string,
        media?: { url: string, profile?: string }[],
        buttons?: CardAction[],
        image?: { url: string, alt?: string },
        autoloop?: boolean,
        autostart?: boolean
    }
}

export type KnownMedia = Media | HeroCard | Thumbnail | Signin | OAuth | Receipt | AudioCard | VideoCard | AnimationCard | FlexCard | AdaptiveCard;
export type Attachment = KnownMedia | UnknownMedia;

export type UserRole = "bot" | "channel" | "user";

export interface User {
    id: string,
    name?: string,
    iconUrl?: string,
    role?: UserRole
}

export interface IActivity {
    type: string,
    channelData?: any,
    channelId?: string,
    conversation?: { id: string },
    eTag?: string,
    from: User,
    id?: string,
    timestamp?: string,
    status?: MessageStatus,
    lastConfirmedStatus?: MessageStatus
}

export type AttachmentLayout = "list" | "carousel";
export enum MessageStatus {
    Created = 0,
    Sent = 1,
    Delivered = 2,
    Read = 3
}
export interface Message extends IActivity {
    type: "message",
    text?: string,
    locale?: string,
    textFormat?: "plain" | "markdown" | "xml",
    attachmentLayout?: AttachmentLayout,
    attachments?: Attachment[],
    entities?: any[],
    suggestedActions?: { actions: CardAction[], to?: string[] },
    speak?: string,
    inputHint?: string,
    value?: object
}

export interface Typing extends IActivity {
    type: "typing"
}

export interface EventActivity extends IActivity {
    type: "event",
    name: string,
    value: any
}

export type Activity = Message | Typing | EventActivity;

interface ActivityGroup {
    activities: Activity[],
    watermark: string
}

interface AppInsightsQueueItem extends IActivity {
    type: string;
    channelData?: any;
    channelId?: string;
    conversation?: { id: string; };
    eTag?: string;
    from: User;
    id?: string;
    timestamp?: string;
    status?: MessageStatus;
    localTimestamp?: string;
    deliveredTimestamp?: string;
}

// These types are specific to this client library, not to Direct Line 3.0

export enum ConnectionStatus {
    Uninitialized,              // the status when the DirectLine object is first created/constructed
    Connecting,                 // currently trying to connect to the conversation
    Online,                     // successfully connected to the converstaion. Connection is healthy so far as we know.
    ExpiredToken,               // last operation errored out with an expired token. Possibly waiting for someone to supply a new one.
    FailedToConnect,            // the initial attempt to connect to the conversation failed. No recovery possible.
    Ended                       // the bot ended the conversation
}

export enum ServiceStatus { // CUSTOM Druid
    Online = 0,
    Warning = 1,
    Offline = 2
}

export interface DirectLineOptions {
    secret?: string,
    token?: string,
    conversationId?: string,
    watermark?: string,
    domain?: string,
    webSocket?: boolean,
    pollingInterval?: number,
    streamUrl?: string,
    // Attached to all requests to identify requesting agent.
    botAgent?: string,
    initiatorUserId?: string, // druid extensions
    initiatorBotId?: string, // druid extensions
    isBotApiEnabled?: boolean, // druid extensions
    druidBotApiUrl?: string, // druid extensions
    instrumentationKey?: string // druid extensions
    serverTimestamp?: string; // druid extension
    backendStartTimestamp?: string;
    backendEndTimestamp?: string;
    withCredentials?: boolean // ajax calls with withCredentials
    conversationStatusInputSubject?: BehaviorSubject<ReplaySubject<{ messageType: number, data: string }>>;
    druidApiUrl?: string;
    isOutOfBandUpload?: boolean;
    apcExternalUrl?: string;
    apcUrl?: string;
}

const lifetimeRefreshToken = 30 * 60 * 1000;
const intervalRefreshToken = lifetimeRefreshToken / 2;
const timeout = 20 * 1000;
const retries = (lifetimeRefreshToken - intervalRefreshToken) / timeout;

const POLLING_INTERVAL_LOWER_BOUND: number = 200; //ms

const errorExpiredToken = new Error("expired token");
const errorConversationEnded = new Error("conversation ended");
const errorFailedToConnect = new Error("failed to connect");

const konsole = {
    log: (message?: any, ...optionalParams: any[]) => {
        if (typeof window !== 'undefined' && (window as any)["botchatDebug"] && message)
            console.log(message, ...optionalParams);
    }
}

export interface IBotConnection {
    connectionStatus$: BehaviorSubject<ConnectionStatus>,
    activity$: Observable<Activity>,
    end(): void,
    referenceGrammarId?: string,
    postActivity(activity: Activity): Observable<string>,
    postSenderAction(senderAction: SenderActionAction),
    getSessionId?: () => Observable<string>,
    conversationStatusWebSocketSubject: BehaviorSubject<ReplaySubject<{ messageType: number, data: string }>>;
    webServiceStatus$: Observable<ServiceStatus>;
    addAWSConnection: (wsUrl: string, connectionToken: string) => any;
    conversationStatusInputSubject?: BehaviorSubject<ReplaySubject<{ messageType: number, data: string }>>;
    sendAjaxRequest: (options: AjaxRequest) => Observable<AjaxResponse>;
    getCurrentToken: () => string;
}

export class DirectLine implements IBotConnection {
    public connectionStatus$ = new BehaviorSubject(ConnectionStatus.Uninitialized);
    public activity$: Observable<Activity>;

    //DRUID Customs
    private badRequests$: BehaviorSubject<{ count: number, payLoad?: { delay: number, operationId: number } }> = new BehaviorSubject({ count: 0 });
    public webServiceStatus$: Observable<ServiceStatus>;

    public conversationStatusWebSocketSubject = new BehaviorSubject<ReplaySubject<{ messageType: number, data: string }>>(null);
    private conversationStatusWebSocketSubjectSubscription: Subscription;
    public conversationStatusWebSocket: ReconnectingWebSocket;
    public conversationStatusInputSubject: BehaviorSubject<ReplaySubject<{ messageType: number, data: string }>> = null; // conversationStatus replaySubject -> input by other directlineConnection

    private domain = "https://directline.botframework.com/v3/directline";
    private webSocket: boolean;

    private conversationId: string;
    private expiredTokenExhaustion: Function;
    private secret: string;
    private token: string;
    private watermark = '';
    private streamUrl: string;
    private _botAgent = '';
    private _userAgent: string;
    public referenceGrammarId: string;
    public isBotApiEnabled: boolean; // must be public
    public druidBotApiUrl: string;
    public druidApiUrl: string;
    public isOutOfBandUpload: boolean;
    public withCredentials?: boolean = false;
    public apcExternalUrl?: string; // used for workarround
    public apcUrl?: string; // used for workarround

    private pollingInterval: number = 1000; //ms

    private tokenRefreshSubscription: Subscription;

    public initiatorUserId: string; // botConnection.user.id forwarding
    public initiatorBotId: string; // botConnection.bot.id forwarding
    private activitiesStatuses: object; // all the statuses received from polling
    private notReadActivities: Activity[] = [];  // activities objects with status not Read
    public typingIsActive: boolean = false; // show typing flag; if is true ignore incoming typing activities
    public conversationStatus_SenderAction: SenderAction;

    public sendMessagesRead: boolean = true; // send read messages to api
    private markMessageStatusesFromBotEmitter = new BehaviorSubject<string>("");
    private markMessageStatusesFromBotEmitter_skippedCount = 0;
    private markMessageStatusesFromRoot2HumanEmitter = new BehaviorSubject<string>("");
    private markMessageStatusesFromRoot2HumanEmitter_skippedCount = 0;

    private markSenderActionsEmitter = new BehaviorSubject<SenderActionAction>(null);
    private applicationInsightsEnabled: boolean = false;
    private appInsightsQueue: AppInsightsQueueItem[] = [];
    public serverTimestamp: Moment;
    private backendStartTimestamp?: string;
    private backendEndTimestamp?: string;
    private appInsights: ApplicationInsights;
    private appInsights_DirectLineConstructorTimestamp: Moment;

    private AWSConnectionWebSocket: ReconnectingWebSocket = null;

    // Workarround for AWS connect -> remove when not used!!!
    private AWSConnectionConnectionToken: string = null;

    constructor(options: DirectLineOptions) {
        //metrica 2 -> ChatStartup TTA
        this.appInsights_DirectLineConstructorTimestamp = moment().utc();
        this.secret = options.secret;
        this.token = options.secret || options.token;
        this.webSocket = (options.webSocket === undefined ? true : options.webSocket) && typeof WebSocket !== 'undefined' && WebSocket !== undefined;

        this.initiatorUserId = options.initiatorUserId;
        this.initiatorBotId = options.initiatorBotId;
        this.serverTimestamp = options.serverTimestamp ? moment(options.serverTimestamp) : moment(new Date(8640000000000000)); // maxDate;

        if (options.domain) {
            this.domain = options.domain;
        }

        if (options.conversationId) {
            this.conversationId = options.conversationId;
        }

        if (options.watermark) {
            this.watermark = options.watermark;
        }

        if (options.streamUrl) {
            if (options.token && options.conversationId) {
                this.streamUrl = options.streamUrl;
            } else {
                console.warn('DirectLineJS: streamUrl was ignored: you need to provide a token and a conversationid');
            }
        }

        this.isBotApiEnabled = options.isBotApiEnabled || false;
        this.druidBotApiUrl = options.druidBotApiUrl || `https://dev-druid-botapi.azurewebsites.net`;
        this.druidApiUrl = options.druidApiUrl || `https://druidapi.develop.druidplatform.com`;
        this.isOutOfBandUpload = options.isOutOfBandUpload;
        this.withCredentials = options.withCredentials || false;
        this.conversationStatusInputSubject = options.conversationStatusInputSubject || null;
        this.apcUrl = options.apcUrl; // used for workarround
        this.apcExternalUrl = options.apcExternalUrl; // used for workarround

        this._botAgent = this.getBotAgent(options.botAgent);

        const parsedPollingInterval = ~~options.pollingInterval;

        if (parsedPollingInterval < POLLING_INTERVAL_LOWER_BOUND) {
            if (typeof options.pollingInterval !== 'undefined') {
                console.warn(`DirectLineJS: provided pollingInterval (${options.pollingInterval}) is under lower bound (200ms), using default of 1000ms`);
            }
        } else {
            this.pollingInterval = parsedPollingInterval;
        }

        this.expiredTokenExhaustion = this.setConnectionStatusFallback(
            ConnectionStatus.ExpiredToken,
            ConnectionStatus.FailedToConnect,
            5
        );

        this.activity$ = (this.webSocket
            ? this.webSocketActivity$()
            : this.pollingGetActivity$()
        ).share();

        if (this.isBotApiEnabled) {


            this.markMessageStatusesFromBotEmitter.asObservable()
                .throttleTime(1000)
                .distinctUntilChanged()
                .catch(error$ => { return Observable.of<string>(); })
                .subscribe((obs) => this.sendMarkMessagesStatuses(obs))
            this.markMessageStatusesFromRoot2HumanEmitter.asObservable()
                .throttleTime(1000)
                .distinctUntilChanged()
                .catch(error$ => { return Observable.of<string>(); })
                .subscribe((obs) => this.sendMarkMessagesStatuses(obs))

            this.markSenderActionsEmitter.asObservable()
                .distinctUntilChanged()
                .debounceTime(100) // for sendMessage programmatically
                // .throttleTime(1000)
                .retryWhen(error$ =>
                    // for now we deem 4xx and 5xx errors as unrecoverable
                    // for everything else (timeouts), retry for a while
                    error$.mergeMap(error => Observable.of(error))
                        .delay(timeout)
                        .take(retries)
                )
                .subscribe((obs) => this.sendMarkSenderActions(obs))
        }


        if (options.instrumentationKey) {
            // appInsights.downloadAndSetup({ instrumentationKey: options.instrumentationKey, maxAjaxCallsPerView: -1 });
            this.applicationInsightsEnabled = true;

            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: options.instrumentationKey,
                    maxAjaxCallsPerView: -1
                }
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();

            this.backendStartTimestamp = options.backendStartTimestamp;
            this.backendEndTimestamp = options.backendEndTimestamp;

        }

        let timerSubscription: Subscription;

        this.webServiceStatus$ = this.badRequests$.asObservable()
            .distinctUntilChanged((prev, next) => {
                if (prev && prev.count > 0 && next.count == 0 && timerSubscription && !timerSubscription.closed) {
                    return true;
                }
                return false;
            })
            .map(update => {
                // if(update.count == 0 && !timerSubscription.closed) {
                //     return ServiceStatus.Warning;
                // }
                if (update.payLoad && update.payLoad.delay && update.count < 5) {
                    if (timerSubscription && !timerSubscription.closed) {
                        timerSubscription.unsubscribe();
                    }
                    timerSubscription = Observable.timer(update.payLoad.delay).subscribe(() => {
                        timerSubscription.unsubscribe();
                        if (this.badRequests$.getValue() === update) { // equals previous status
                            this.badRequests$.next({ count: 0 });
                        }
                    });
                }

                if (update.count > 4) {
                    if (timerSubscription && !timerSubscription.closed) {
                        timerSubscription.unsubscribe();
                    }
                    // is offline
                }
                return update.count == 0 ? ServiceStatus.Online : update.count < 5 ? ServiceStatus.Warning : ServiceStatus.Offline;
            })
            .distinctUntilChanged();
    }

    // Every time we're about to make a Direct Line REST call, we call this first to see check the current connection status.
    // Either throws an error (indicating an error state) or emits a null, indicating a (presumably) healthy connection
    private checkConnection(once = false) {
        let obs = this.connectionStatus$
            .flatMap(connectionStatus => {
                if (connectionStatus === ConnectionStatus.Uninitialized) {
                    this.connectionStatus$.next(ConnectionStatus.Connecting);

                    //if token and streamUrl are defined it means reconnect has already been done. Skipping it.
                    if (this.token && this.streamUrl) {
                        this.connectionStatus$.next(ConnectionStatus.Online);
                        return Observable.of(connectionStatus);
                    } else {
                        return this.startConversation().do(conversation => {
                            this.conversationId = conversation.conversationId;
                            this.token = this.secret || conversation.token;
                            this.streamUrl = conversation.streamUrl;
                            this.referenceGrammarId = conversation.referenceGrammarId;
                            if (!this.secret)
                                this.refreshTokenLoop();

                            this.connectionStatus$.next(ConnectionStatus.Online);
                        }, error => {
                            this.connectionStatus$.next(ConnectionStatus.FailedToConnect);
                        })
                            .map(_ => connectionStatus);
                    }
                }
                else {
                    return Observable.of(connectionStatus);
                }
            })
            .filter(connectionStatus => connectionStatus != ConnectionStatus.Uninitialized && connectionStatus != ConnectionStatus.Connecting)
            .flatMap(connectionStatus => {
                switch (connectionStatus) {
                    case ConnectionStatus.Ended:
                        return Observable.throw(errorConversationEnded);

                    case ConnectionStatus.FailedToConnect:
                        return Observable.throw(errorFailedToConnect);

                    case ConnectionStatus.ExpiredToken:
                        return Observable.of(connectionStatus);

                    default:
                        return Observable.of(connectionStatus);
                }
            })

        return once ? obs.take(1) : obs;
    }

    setConnectionStatusFallback(
        connectionStatusFrom: ConnectionStatus,
        connectionStatusTo: ConnectionStatus,
        maxAttempts = 5
    ) {
        maxAttempts--;
        let attempts = 0;
        let currStatus = null;
        return (status: ConnectionStatus): ConnectionStatus => {
            if (status === connectionStatusFrom && currStatus === status && attempts >= maxAttempts) {
                attempts = 0
                return connectionStatusTo;
            }
            attempts++;
            currStatus = status;
            return status;
        };
    }

    private expiredToken() {
        const connectionStatus = this.connectionStatus$.getValue();
        if (connectionStatus != ConnectionStatus.Ended && connectionStatus != ConnectionStatus.FailedToConnect)
            this.connectionStatus$.next(ConnectionStatus.ExpiredToken);

        const protectedConnectionStatus = this.expiredTokenExhaustion(this.connectionStatus$.getValue());
        this.connectionStatus$.next(protectedConnectionStatus);
    }

    private startConversation() {
        try {
            if (this.backendEndTimestamp && this.backendStartTimestamp) {
                this.reportBackendMetric(moment(this.backendStartTimestamp), moment(this.backendEndTimestamp));
            }
        } catch (ex) {

        }
        //if conversationid is set here, it means we need to call the reconnect api, else it is a new conversation
        const url = this.conversationId
            ? `${this.domain}/conversations/${this.conversationId}?watermark=${this.watermark || (this.webSocket ? '0' : this.watermark)}`
            : `${this.domain}/conversations`;
        const method = this.conversationId ? "GET" : "POST";

        let baseUrl = this.druidBotApiUrl + //https://dev-druid-botapi.azurewebsites.net
            `/GetStatus?ConversationId=${this.conversationId}&Watermark=${this.watermark || 0}`;

        return (this.isBotApiEnabled ?
            Observable.ajax({
                method: 'GET',
                url: baseUrl,
                responseType: "json",
                timeout,
                withCredentials: this.withCredentials,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    ...this.commonHeaders()
                }

            }).map(ajaxResponse => ajaxResponse.response as ConversationStatus) :
            Observable.create((observer: any) => {
                observer.next(new ConversationStatus());
                observer.complete();
            }))
            .catch(err => {
                return Observable.create((observer: any) => {
                    observer.next(new ConversationStatus());
                    observer.complete();
                })
            })
            .flatMap(data => {

                if (this.isBotApiEnabled) {
                    try {
                        let newStatuses = {};
                        if (!!data.messageStatuses) {
                            data.messageStatuses.map(ms => newStatuses[ms.activityId] = ms.status);
                        }
                        this.activitiesStatuses = { ...this.activitiesStatuses, ...(newStatuses) };
                    } catch (ex) {

                    }
                }

                return Observable.ajax({
                    method,
                    url,
                    timeout,
                    withCredentials: this.withCredentials,
                    headers: {
                        "Accept": "application/json",
                        ...this.commonHeaders()
                    }
                })

                    .map(ajaxResponse => ajaxResponse.response as Conversation)
                    .retryWhen(error$ =>
                        // for now we deem 4xx and 5xx errors as unrecoverable
                        // for everything else (timeouts), retry for a while
                        error$.mergeMap(error => error.status >= 400 && error.status < 600
                            ? Observable.throw(error)
                            : Observable.of(error)
                        )
                            .delay(timeout)
                            .take(retries)
                    )
            });


    }

    private refreshTokenLoop() {
        this.tokenRefreshSubscription = Observable.interval(intervalRefreshToken)
            .flatMap(_ => this.refreshToken())
            .subscribe(token => {
                konsole.log("refreshing token", token, "at", new Date());
                this.token = token;
            });
    }

    private refreshToken() {
        return this.checkConnection(true)
            .flatMap(_ =>
                Observable.ajax({
                    method: "POST",
                    url: `${this.domain}/tokens/refresh`,
                    timeout,
                    withCredentials: this.withCredentials,
                    headers: {
                        ...this.commonHeaders()
                    }
                })
                    .map(ajaxResponse => ajaxResponse.response.token as string)
                    .retryWhen(error$ => error$
                        .mergeMap(error => {
                            if (error.status === 403) {
                                // if the token is expired there's no reason to keep trying
                                this.expiredToken();
                                return Observable.throw(error);
                            } else if (error.status === 404) {
                                // If the bot is gone, we should stop retrying
                                return Observable.throw(error);
                            }

                            return Observable.of(error);
                        })
                        .delay(timeout)
                        .take(retries)
                    )
            )
    }

    public reconnect(conversation: Conversation) {
        this.token = conversation.token;
        this.streamUrl = conversation.streamUrl;
        if (this.connectionStatus$.getValue() === ConnectionStatus.ExpiredToken)
            this.connectionStatus$.next(ConnectionStatus.Online);
    }

    public getCurrentToken() {
        return this.token;
    }

    end() {
        if (this.tokenRefreshSubscription) {
            this.tokenRefreshSubscription.unsubscribe();
        }

        if (this.conversationStatusWebSocket) {
            this.conversationStatusWebSocket.close();
        }
        try {

            this.connectionStatus$.next(ConnectionStatus.Ended);
        } catch (e) {
            if (e === errorConversationEnded)
                return;
            throw (e);
        }
    }

    getSessionId(): Observable<string> {
        // If we're not connected to the bot, get connected
        // Will throw an error if we are not connected
        konsole.log("getSessionId");
        return this.checkConnection(true)
            .flatMap(_ =>
                Observable.ajax({
                    method: "GET",
                    url: `${this.domain}/session/getsessionid`,
                    withCredentials: this.withCredentials,
                    timeout,
                    headers: {
                        "Content-Type": "application/json",
                        ...this.commonHeaders()
                    }
                })
                    .map(ajaxResponse => {
                        if (ajaxResponse && ajaxResponse.response && ajaxResponse.response.sessionId) {
                            konsole.log("getSessionId response: " + ajaxResponse.response.sessionId);
                            return ajaxResponse.response.sessionId as string;
                        }
                        return '';
                    })
                    .catch(error => {
                        konsole.log("getSessionId error: " + error.status);
                        return Observable.of('');
                    })
            )
            .catch(error => this.catchExpiredToken(error));
    }

    postActivity(activity: Activity) {
        // Use postMessageWithAttachments for messages with attachments that are local files (e.g. an image to upload)
        // Technically we could use it for *all* activities, but postActivity is much lighter weight
        // So, since WebChat is partially a reference implementation of Direct Line, we implement both.
        if (activity.type === "message" && activity.attachments && activity.attachments.length > 0)
            return this.postMessageWithAttachments(activity);

        if (this.applicationInsightsEnabled) {
            let newAppInsightsQueueItem: AppInsightsQueueItem = activity as AppInsightsQueueItem;
            newAppInsightsQueueItem.localTimestamp = newAppInsightsQueueItem.timestamp;

            this.appInsightsQueue.push(newAppInsightsQueueItem);
        }
        // If we're not connected to the bot, get connected
        // Will throw an error if we are not connected
        konsole.log("postActivity", activity);
        return this.checkConnection(true)
            .flatMap(_ =>
                Observable.ajax({
                    method: "POST",
                    url: `${this.domain}/conversations/${this.conversationId}/activities`,
                    body: activity,
                    timeout,
                    withCredentials: this.withCredentials,
                    headers: {
                        "Content-Type": "application/json",
                        ...this.commonHeaders()
                    },
                })
                    .map(ajaxResponse => {
                        // this.AWSConnectionWebSocket && this.AWSConnectionWebSocket.send();

                        // Workarround for AWS connect -> remove when not used!!!
                        this.AWSConnectionConnectionToken && activity.type == "message" && this.isFromMe(activity) &&
                        Observable.ajax({
                            method: "POST",
                            url: `https://participant.connect.us-west-2.amazonaws.com/participant/message`,
                            body: {
                                ClientToken: (() => {
                                    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                                      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                                      return v.toString(16);
                                    });
                                  })(),
                                Content: activity.text,
                                ContentType: "text/plain"
                            },
                            timeout,
                            withCredentials: this.withCredentials,
                            createXHR: function () {
                                var xhr = new XMLHttpRequest();
                                var setRequestHeader = xhr.setRequestHeader;
                                xhr.setRequestHeader = function(name, value) {
                                    // Ignore the X-Requested-With header
                                    if (name == 'X-Requested-With') return;
                                    // Otherwise call the native setRequestHeader method
                                    // Note: setRequestHeader requires its 'this' to be the xhr object,
                                    // which is what 'this' is here when executed.
                                    setRequestHeader.call(this, name, value);
                                }
                                return xhr;
                            },
                            headers: {
                                "Content-Type": "application/json",
                                "x-amz-bearer": this.AWSConnectionConnectionToken
                                // ...this.commonHeaders()
                            },
                        })
                        .catch(error => error)
                        .map(resp => resp).subscribe();
                        return ajaxResponse.response.id as string;
                    })
                    .catch(error => this.catchPostError(error, activity))
            )
            .catch(error => this.catchExpiredToken(error));
    }

    postSenderAction(senderAction: SenderActionAction) {
        if (!!this.markSenderActionsEmitter)
            this.markSenderActionsEmitter.next(senderAction);
    }

    dedupeFilenames(array: string[]) {
        const nextArray: string[] = [];

        array.forEach(value => {
            const { extname, name } = this.parseFilename(value);
            let count = 0;
            let nextValue = value;

            while (nextArray.includes(nextValue)) {
                nextValue = [name, `(${ (++count) })`].filter(segment => segment).join(' ') + extname;
            }

            nextArray.push(nextValue);
        });

        return nextArray;
    }

    parseFilename(filename) {
        if (!filename) {
            return {
                extname: '',
                name: ''
            };
        } else if (~filename.indexOf('.')) {
            const [extensionWithoutDot, ...nameSegments] = filename.split('.').reverse();

            return {
                extname: '.' + extensionWithoutDot,
                name: nameSegments.reverse().join('.')
            };
        } else {
            return {
                extname: '',
                name: filename
            };
        }
    }

    private postMessageWithAttachments(message: Message) {
        const { attachments } = message;
        // We clean the attachments but making sure every attachment has unique name.
        // If the file do not have a name, Chrome will assign "blob" when it is appended to FormData.
        const attachmentNames: string[] = this.dedupeFilenames(attachments.map((media: Media) => media.name || 'blob'));
        const cleansedAttachments = attachments.map((attachment: Media, index: number) => ({
            ...attachment,
            name: attachmentNames[index]
        }));
        let formData: FormData;

        // If we're not connected to the bot, get connected
        // Will throw an error if we are not connected
        return this.checkConnection(true)
        .flatMap(_ => {
            // To send this message to DirectLine we need to deconstruct it into a "template" activity
            // and one blob for each attachment.
            formData = new FormData();
            formData.append('activity', new Blob([JSON.stringify({
                ...message,
                // Removing contentUrl from attachment, we will send it via multipart
                attachments: cleansedAttachments.map(({ contentUrl: string, ...others }) => ({ ...others }))
            })], { type: 'application/vnd.microsoft.activity' }));

            return Observable.from(cleansedAttachments)
            .flatMap((media: Media) =>
                Observable.ajax({
                    method: "GET",
                    url: media.contentUrl,
                    responseType: 'arraybuffer',
                    withCredentials: this.withCredentials
                })
                .do(ajaxResponse =>
                    formData.append('file', new Blob([ajaxResponse.response], { type: media.contentType }), media.name)
                )
            )
            .count()
        })
        .flatMap(_ =>
            Observable.ajax({
                method: "POST",
                url: `${(this.isOutOfBandUpload === true && this.druidApiUrl && `${this.druidApiUrl}/v3/directline`) || this.domain}/conversations/${this.conversationId}/upload?userId=${message.from.id}`,
                body: formData,
                timeout,
                headers: {
                    ...this.commonHeaders()
                }
            })
            .map(ajaxResponse => ajaxResponse.response.id as string)
            .catch(error => this.catchPostError(error))
        )
        .catch(error => this.catchPostError(error));
    }

    private catchPostError(error: any, activity?: Activity) {

        this.badRequests$.next({ count: 1, payLoad: { delay: 22000, operationId: Date.now() } });


        if (activity && this.applicationInsightsEnabled) {
            let foundQueueItemIdx = this.appInsightsQueue.findIndex(fi => fi.channelData && activity.channelData && fi.channelData.clientActivityId === activity.channelData.clientActivityId);
            let momentUtcNow: Moment = moment().utc();
            if (foundQueueItemIdx >= 0) {
                let foundQueueItem = this.appInsightsQueue[foundQueueItemIdx];
                this.reportFailedServerPost(this.appInsightsQueue[foundQueueItemIdx], momentUtcNow.diff(moment(foundQueueItem.localTimestamp)), momentUtcNow, !this.isFromBotOrIsFromRoot2Human(activity));
            }
        }


        if (error.status === 403)
            // token has expired (will fall through to return "retry")
            this.expiredToken();
        else if (error.status >= 400 && error.status < 500)
            // more unrecoverable errors
            return Observable.throw(error);
        return Observable.of("retry");
    }

    private catchExpiredToken(error: any) {
        return error === errorExpiredToken
            ? Observable.of("retry")
            : Observable.throw(error);
    }

    public isFromMe(activity: Activity) {
        return !!activity.from && (activity.from.role === 'user' || activity.from.id === this.initiatorUserId);
    }

    private sendMarkMessagesStatuses(outputJson: string) {
        if (!document.hidden) {
            let baseUrl = this.druidBotApiUrl + "/MarkMessageStatuses";
            if (!!outputJson) {
                return Observable.ajax({
                    method: 'POST',
                    url: baseUrl,
                    responseType: "json",
                    body: outputJson,
                    timeout,
                    withCredentials: this.withCredentials,
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        ...this.commonHeaders()
                    }

                })
                    .catch(error$ => Observable.empty<AjaxResponse>())
                    .subscribe(resp => resp.response);
            }
        }
    }

    private sendMarkSenderActions(senderAction: SenderActionAction) {

        if (senderAction === null || senderAction === undefined)
            return;

        var output = new MarkSenderActionInput();
        output.conversationId = this.conversationId;
        output.isFromBot = false;

        output.senderAction = new SenderAction();
        output.senderAction.action = senderAction;
        output.senderAction.dateTimeUtc = moment().utc();

        let baseUrl = this.druidBotApiUrl + "/MarkSenderActions";
        if (!!output) {
            return Observable.ajax({
                method: 'POST',
                url: baseUrl,
                responseType: "json",
                body: output,
                timeout,
                withCredentials: this.withCredentials,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    ...this.commonHeaders()
                }

            })
                .retryWhen(error$ =>
                    // if is typing on, ignore
                    // if token expired, ignore
                    // for everything else (timeouts), retry for a while
                    error$.mergeMap(error => senderAction !== SenderActionAction._0 || error.status >= 400 && error.status < 500
                        ? Observable.throw(error)
                        : Observable.of(error)
                    ).delay(timeout)
                    .take(retries)
                ).subscribe(resp => resp.response);
        }

    }

    private isFromBotOrIsFromRoot2Human(activity: Activity) {
        return !(activity.channelData && activity.channelData.routedMessageId);
    }
    private markMessagesStatuses() {
        if (this.isBotApiEnabled) {
            try {
                if (!!this.notReadActivities) {
                    var outputFromBot = new MarkMessageStatusInput();
                    outputFromBot.conversationId = this.conversationId;
                    outputFromBot.isFromBot = true;
                    var outputFromRoot2Human = new MarkMessageStatusInput();
                    outputFromRoot2Human.conversationId = this.conversationId;
                    outputFromRoot2Human.isFromBot = false;
                    if (this.notReadActivities.filter(f => !this.isFromMe(f)).length > 0) {
                        outputFromBot.messageStatuses = this.notReadActivities.filter(f => !this.isFromMe(f) && (this.sendMessagesRead || f.lastConfirmedStatus !== f.status) && this.isFromBotOrIsFromRoot2Human(f)).map(nra => {
                            let ms = new MessageStatusInputDto();
                            ms.activityId = nra.id; // !!nra.channelData ? (nra.channelData.sourceMessageId || nra.id) : nra.id;
                            ms.routedActivityId = nra.channelData && nra.channelData.routedMessageId || null; //routedActivityId
                            ms.sourceMessageId = nra.channelData && nra.channelData.sourceMessageId || null; //routedActivityId
                            ms.correlationId = nra.channelData && nra.channelData.correlationId || null;
                            if(nra.lastConfirmedStatus === undefined || nra.lastConfirmedStatus === MessageStatus.Sent || nra.lastConfirmedStatus === MessageStatus.Created) {
                                ms.isPreviousStatusSkipped = !!this.sendMessagesRead;
                            }

                            // ms.dateTimeUtc = moment().utc();
                            ms.status = !!this.sendMessagesRead ? 3 : 2; //MessageStatus.Read : MessageStatus.Delivered;
                            return ms;
                        });

                        outputFromRoot2Human.messageStatuses = this.notReadActivities.filter(f => !this.isFromMe(f) && (this.sendMessagesRead || f.lastConfirmedStatus !== f.status) && !this.isFromBotOrIsFromRoot2Human(f)).map(nra => {
                            let ms = new MessageStatusInputDto();
                            ms.activityId = nra.id; // !!nra.channelData ? (nra.channelData.sourceMessageId || nra.id) : nra.id;
                            ms.routedActivityId = nra.channelData && nra.channelData.routedMessageId || null; //routedActivityId
                            ms.sourceMessageId = nra.channelData && nra.channelData.sourceMessageId || null; //routedActivityId
                            ms.correlationId = nra.channelData && nra.channelData.correlationId || null;
                            if(nra.lastConfirmedStatus === undefined || nra.lastConfirmedStatus === MessageStatus.Sent || nra.lastConfirmedStatus === MessageStatus.Created) {
                                ms.isPreviousStatusSkipped = !!this.sendMessagesRead;
                            }

                            // ms.dateTimeUtc = moment().utc();
                            ms.status = !!this.sendMessagesRead ? 3 : 2; //MessageStatus.Read : MessageStatus.Delivered;
                            return ms;
                        });


                        if (!!outputFromBot && !!outputFromBot.messageStatuses && outputFromBot.messageStatuses.length > 0 && !!outputFromBot.conversationId) {
                            const currentValue = JSON.stringify(this.markMessageStatusesFromBotEmitter.getValue());
                            const toSetValue = JSON.stringify(outputFromBot.toJSON());
                            if(currentValue !== toSetValue || this.markMessageStatusesFromBotEmitter_skippedCount > 10) {
                                this.markMessageStatusesFromBotEmitter_skippedCount = 0;
                                this.markMessageStatusesFromBotEmitter.next(outputFromBot.toJSON());
                            } else {
                                this.markMessageStatusesFromBotEmitter_skippedCount++;
                            }
                            // this.useSkippableEmits.bind(this)(this.markMessageStatusesFromBotEmitter, outputFromBot, this.markMessageStatusesFromBotEmitter_skippedCount);
                        }

                        if (!!outputFromRoot2Human && !!outputFromRoot2Human.messageStatuses && outputFromRoot2Human.messageStatuses.length > 0 && !!outputFromRoot2Human.conversationId) {
                            // this.markMessageStatusesFromRoot2HumanEmitter.next(outputFromRoot2Human.toJSON());

                            const currentValue = JSON.stringify(this.markMessageStatusesFromRoot2HumanEmitter.getValue());
                            const toSetValue = JSON.stringify(outputFromRoot2Human.toJSON());
                            if(currentValue !== toSetValue || this.markMessageStatusesFromRoot2HumanEmitter_skippedCount > 10) {
                                this.markMessageStatusesFromRoot2HumanEmitter_skippedCount = 0;
                                this.markMessageStatusesFromRoot2HumanEmitter.next(outputFromRoot2Human.toJSON());
                            } else {
                                this.markMessageStatusesFromRoot2HumanEmitter_skippedCount++;
                            }
                            // this.useSkippableEmits(this.markMessageStatusesFromRoot2HumanEmitter, outputFromRoot2Human, this.markMessageStatusesFromRoot2HumanEmitter_skippedCount);
                        }
                    }
                }
            } catch (ex) {
                return Observable.empty();
            }
        }

        return Observable.empty();
    }

    // private useSkippableEmits(emitter: BehaviorSubject<any>, nextValue: MarkMessageStatusInput, skippedCount: number) {
    //     const currentValue = JSON.stringify(emitter.getValue());
    //     const toSetValue = JSON.stringify(nextValue.toJSON());
    //     if(currentValue !== toSetValue || skippedCount > 10) {
    //         skippedCount = 0;
    //         emitter.next(nextValue.toJSON());
    //         return true;
    //     } else {
    //         skippedCount = skippedCount + 1;
    //         return false;
    //     }
    // }

    private pollingGetMessagesStatus() {

        if (this.isBotApiEnabled) {
            try {
                 // TODO: move to directlineOptions
                let output = [];
                if (!!this.notReadActivities) {
                    output = this.notReadActivities
                        .filter(act => (act.type === "message") && (act.status !== MessageStatus.Read)) //  (act.type === "message") &&
                        .map(act => act.id); // !!act.channelData ? (act.channelData.sourceMessageId || act.id) : act.id
                }
                let baseUrl = this.druidBotApiUrl +
                    `/GetStatus?ConversationId=${this.conversationId}`;
                if (output.length) {
                    if (output.length < 9) {
                        baseUrl += "&" + output.map(actId => `forActivities=${encodeURIComponent(actId)}`).join("&");
                    } else {
                        let minWatermark = Math.min(...output.map(actId => {
                            try {
                                return parseInt(actId.split("|")[1]);
                            }
                            catch (ex) {
                                return Number.MAX_SAFE_INTEGER;
                            }
                        }).filter(f => f)); // not null -> encrypted ids
                        baseUrl += `&Watermark=${minWatermark || this.watermark}`;
                    }
                }
                return true || output.length ? // overwritten: check for status even no unread messages
                    Observable.ajax({
                        method: 'GET',
                        url: baseUrl,
                        responseType: "json",
                        timeout,
                        withCredentials: this.withCredentials,
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            ...this.commonHeaders()
                        }

                    }).map(ajaxResponse => ajaxResponse.response as ConversationStatus)
                        .catch(err => {
                            return Observable.create((observer: any) => {
                                observer.next(new ConversationStatus());
                                observer.complete();
                            })
                        })
                    : Observable.create((observer: any) => {
                        observer.next(new ConversationStatus());
                        observer.complete();
                    });

            } catch (ex) {
                return Observable.empty<ConversationStatus>();
            }
        } else {
            return Observable.empty<ConversationStatus>();
        }
    }

    private updateConversationStatus(response: any) {
        try {
            let newStatuses = {};
            const conversationStatus = response;
            if (!!conversationStatus) {

                if (!!conversationStatus.messageStatuses) {
                    conversationStatus.messageStatuses.map(ms => newStatuses[ms.activityId] = ms.status);
                }

                if (conversationStatus.botAction) {
                    if (!conversationStatus.routedConversationId || conversationStatus.routedConversationId == this.conversationId) {
                        this.conversationStatus_SenderAction = conversationStatus.botAction;
                    }

                }
            }
            this.activitiesStatuses = { ...this.activitiesStatuses, ...(newStatuses) };


        } catch (ex) {

        }
    }

    private pollingGetActivity$() {
        const poller$: Observable<AjaxResponse> = Observable.create((subscriber: Subscriber<any>) => {
            // A BehaviorSubject to trigger polling. Since it is a BehaviorSubject
            // the first event is produced immediately.
            const trigger$ = new BehaviorSubject<any>({});

            //             const source = timer(2000);
            // const emitter: BehaviorSubject<any> = new BehaviorSubject(-1);
            // emitter.subscribe().

            // const subscribe = source.subscribe( value => emitter.next(value));

            // const markSessagesStatus$ = new BehaviorSubject<any>({});
            // markSessagesStatus$.pipe(tap(() => {})), switchMap(() => timeInterval(10))

            trigger$.subscribe(() => {
                if (this.connectionStatus$.getValue() === ConnectionStatus.Online) {
                    const startTimestamp = Date.now();

                    if (this.isBotApiEnabled) {
                        Observable.from(this.markMessagesStatuses())
                            .catch(() => Observable.empty())
                            .subscribe();
                    }


                    //druid polling for new messages status
                    (this.isBotApiEnabled ?
                        Observable.from(this.pollingGetMessagesStatus())
                            .catch(() => Observable.empty<ConversationStatus>()) :
                        Observable.create((observer: any) => {
                            observer.next(new ConversationStatus());
                            observer.complete();
                        })
                    )
                        .subscribe(ajaxResponse => {

                            if (this.isBotApiEnabled) {
                                this.updateConversationStatus(ajaxResponse);
                            }
                        });

                    const stopwatch = new Stopwatch();
                    stopwatch.start();
                    Observable.ajax({
                        headers: {
                            Accept: 'application/json',
                            ...this.commonHeaders()
                        },
                        method: 'GET',
                        url: `${this.domain}/conversations/${this.conversationId}/activities?watermark=${this.watermark}`,
                        timeout,
                        withCredentials: this.withCredentials
                    }).subscribe(
                        (result: AjaxResponse) => {
                            subscriber.next(result);
                            stopwatch.stop();
                            const delay = stopwatch.getTime();
                            if (delay < 5000) {
                                this.badRequests$.next({ count: 0 });
                            } else {
                                this.badRequests$.next({ count: 1, payLoad: { delay: 22000, operationId: Date.now() } });
                            }
                            Observable.timer(Math.max(0, this.pollingInterval - Date.now() + startTimestamp)).subscribe(timer => trigger$.next(null));
                            // setTimeout(() => trigger$.next(null), Math.max(0, this.pollingInterval - Date.now() + startTimestamp));
                        },
                        (error: any) => {
                            switch (error.status) {
                                case 403:
                                    this.connectionStatus$.next(ConnectionStatus.ExpiredToken);
                                    setTimeout(() => trigger$.next(null), this.pollingInterval);
                                    break;
                                case 404:
                                    this.connectionStatus$.next(ConnectionStatus.Ended);
                                    break;
                                // case 500:
                                // case 502:
                                // case 503:
                                // case 504:
                                //     setTimeout(() => trigger$.next(null), this.pollingInterval);
                                //     break;

                                default:
                                    // propagate the error
                                    // subscriber.error(error);
                                    stopwatch.stop();
                                    if (stopwatch.getTime() > timeout) { // on second timeout attempt set offline value
                                        this.badRequests$.next({ count: this.badRequests$.getValue().count == 0 ? this.badRequests$.getValue().count + 1 : 100, payLoad: { delay: 22000, operationId: Date.now() } });
                                    } else {
                                        this.badRequests$.next({ count: this.badRequests$.getValue().count + 1, payLoad: { delay: 22000, operationId: Date.now() } });
                                    }

                                    setTimeout(() => trigger$.next(null), this.pollingInterval);
                                    break;
                            }
                        }
                    );


                }
            });
        });

        return this.checkConnection()
            .flatMap(_ => poller$
                .catch(() => Observable.empty<AjaxResponse>())
                .map(ajaxResponse => ajaxResponse.response as ActivityGroup)
                .flatMap(activityGroup => this.observableFromActivityGroup(activityGroup)));
    }

    reportServerPost(humanActivity: AppInsightsQueueItem, miliseconds: number, momentUtcNow: Moment) {

        var properties = {
            humanTimestamp: humanActivity.timestamp,
            localHumanTimestamp: humanActivity.localTimestamp,
            localTimestamp: momentUtcNow,
            // botId: humanActivity.from.id,
            // botName: humanActivity.from.name,
            conversationId: humanActivity.conversation.id,
            humanActivityId: humanActivity.id,
            isRootToHuman: "false"
        };

        this.appInsights.context.telemetryTrace.parentID = humanActivity.conversation.id;
        this.appInsights.trackMetric({ name: `Bot - Server Post`, average: miliseconds }, properties);
        // appInsights.trackMetric({name: 'Bot - Server Post', average : miliseconds,   properties: properties});
        this.appInsights.flush();
    }

    reportFailedServerPost(humanActivity: AppInsightsQueueItem, miliseconds: number, momentUtcNow: Moment, isRootToHuman: boolean) {

        var properties = {
            humanTimestamp: humanActivity.timestamp,
            localHumanTimestamp: humanActivity.localTimestamp,
            localTimestamp: momentUtcNow,
            // botId: humanActivity.from.id,
            // botName: humanActivity.from.name,
            conversationId: this.conversationId,
            humanActivityId: humanActivity.channelData && humanActivity.channelData.clientActivityId,
            isRootToHuman: isRootToHuman
        };

        this.appInsights.context.telemetryTrace.parentID = this.conversationId;
        this.appInsights.trackMetric({ name: `Bot - Failed Server Post`, average: miliseconds }, properties);
        // appInsights.trackMetric({name: 'Bot - Server Post', average : miliseconds,   properties: properties});
        this.appInsights.flush();
    }


    reportActivityMetric(humanActivity: AppInsightsQueueItem, botActivity?: AppInsightsQueueItem) {

        if (humanActivity && botActivity) {
            var msServer = moment(botActivity.timestamp).diff(moment(humanActivity.timestamp));
            var msLocal = moment(botActivity.localTimestamp).diff(moment(humanActivity.localTimestamp));
            let properties = {
                humanTimestamp: humanActivity.timestamp,
                botTimestamp: botActivity.timestamp,
                localHumanTimestamp: humanActivity.localTimestamp,
                localBotTimestamp: botActivity.localTimestamp,
                botId: botActivity.from.id,
                botName: botActivity.from.name,
                conversationId: botActivity.conversation.id,
                humanActivityId: humanActivity.id,
                botActivityId: botActivity.id,
                isRootToHuman: "false",
                correlationId: botActivity.channelData ? botActivity.channelData.correlationId : ''
            };

            // appInsights.context.operation.parentId = botActivity.conversation.id;
            this.appInsights.context.telemetryTrace.parentID = botActivity.conversation.id;
            this.appInsights.trackMetric({ name: 'Bot - Client TTA', average: msLocal < 900 * 1000 ? msLocal : 0 }, properties);
            this.appInsights.trackMetric({ name: 'Bot - Server TTA', average: msServer < 900 * 1000 ? msServer : 0 }, properties);

            this.appInsights.flush();

        }
        else if (humanActivity) {
            let properties = {
                humanTimestamp: humanActivity.timestamp,
                localHumanTimestamp: humanActivity.localTimestamp,
                botId: this.initiatorBotId,
                botName: this.initiatorBotId,
                conversationId: humanActivity.conversation.id,
                humanActivityId: humanActivity.id,
                isRootToHuman: "false"
            };

            // appInsights.context.operation.parentId = humanActivity.conversation.id;
            this.appInsights.context.telemetryTrace.parentID = humanActivity.conversation.id;
            this.appInsights.trackMetric({ name: `Bot -${(humanActivity as any) && (humanActivity as any).name ? (' ' + (humanActivity as any).name + ' ') : ''}No response`, average: 1 }, properties);
            this.appInsights.flush();

        }

    };

    reportChatStartupMetric(authenticationEvent: AppInsightsQueueItem, startTimestamp: Moment, endTimestamp: Moment) {
        this.appInsights.context.telemetryTrace.parentID = authenticationEvent.conversation.id;
        var miliseconds = moment(endTimestamp).diff(moment(startTimestamp));

        let properties = {
            localStartTimestamp: startTimestamp.utc().toISOString(),
            localEndTimestamp: endTimestamp.utc().toISOString(),
            botId: this.initiatorBotId,
            botName: this.initiatorBotId,
            userId: this.initiatorUserId,
            conversationId: authenticationEvent.conversation.id,
            humanActivityId: authenticationEvent.id
        };

        this.appInsights.trackMetric({ name: 'Bot - Chat Startup', average: miliseconds }, properties);
        // appInsights.trackMetric({name: 'Bot - Server Post', average : miliseconds,   properties: properties});
        this.appInsights.flush();
    }

    reportBackendMetric(startTimestamp: Moment, endTimestamp: Moment) {
        this.appInsights.context.telemetryTrace.parentID = this.conversationId;
        var miliseconds = moment(endTimestamp).diff(moment(startTimestamp));

        let properties = {
            localStartTimestamp: startTimestamp.utc().toISOString(),
            localEndTimestamp: endTimestamp.utc().toISOString(),
            botId: this.initiatorBotId,
            botName: this.initiatorBotId,
            userId: this.initiatorUserId,
            conversationId: this.conversationId
        };

        this.appInsights.trackMetric({ name: 'Bot - Backend', average: miliseconds }, properties);
        // appInsights.trackMetric({name: 'Bot - Server Post', average : miliseconds,   properties: properties});
        this.appInsights.flush();
    }

    private observableFromActivityGroup(activityGroup: ActivityGroup) {
        if (activityGroup.watermark) {
            this.watermark = activityGroup.watermark;
        }

        let momentUtcNow: Moment = moment().utc();
        activityGroup.activities.forEach(act => {

            if (this.applicationInsightsEnabled) {
                try {

                    if (act.type === 'event' && act.name === 'Authentication' && this.serverTimestamp.isSameOrBefore(moment(act.timestamp))) {
                        this.reportChatStartupMetric(act, this.appInsights_DirectLineConstructorTimestamp, momentUtcNow);

                        let newAppInsightsQueueItem: AppInsightsQueueItem = act as AppInsightsQueueItem; // copied from postActivity above -> simulate user input message
                        newAppInsightsQueueItem.localTimestamp = momentUtcNow.toISOString();

                        this.appInsightsQueue.push(newAppInsightsQueueItem);
                    }
                    //if type is event & name is Authentication & servertime < activity.timestamp -> Report metric 2(localTimestamps) ChatStartup TTA -> and add to queue for metric 3

                    let foundQueueItemIdx = this.appInsightsQueue.findIndex(fi => fi.channelData && act.channelData && fi.channelData.clientActivityId === act.channelData.clientActivityId);
                    if (foundQueueItemIdx >= 0) {
                        let foundQueueItem = this.appInsightsQueue[foundQueueItemIdx];
                        foundQueueItem.id = act.id;
                        foundQueueItem.conversation = act.conversation;
                        foundQueueItem.timestamp = act.timestamp;
                        //localTimestamp was set on postActivitiy
                        foundQueueItem.deliveredTimestamp = momentUtcNow.toISOString();
                        if (act.type !== 'event' && (act as any).name !== 'Authentication') { //should not pass filter above but skip if is authentication event (is injected by flowengine, not sent by client)
                            this.reportServerPost(foundQueueItem, momentUtcNow.diff(moment(foundQueueItem.localTimestamp)), momentUtcNow);
                        }
                    } else {
                        if (!!(act as any).replyToId) {
                            let replyToId: string = (act as any).replyToId;
                            let replyToIdItemIdx = this.appInsightsQueue.findIndex(fi => fi.id === replyToId);
                            if (replyToIdItemIdx >= 0) {
                                let replyToIdItem = this.appInsightsQueue[replyToIdItemIdx];
                                let botActivity = (act as AppInsightsQueueItem);
                                botActivity.localTimestamp = momentUtcNow.toISOString();
                                this.reportActivityMetric(replyToIdItem, botActivity);
                                //send response received telemetry and then: --->>>
                                if (replyToIdItemIdx > 0) {
                                    let toRemoveItems = this.appInsightsQueue.filter((f, idx) => idx < replyToIdItemIdx);
                                    this.appInsightsQueue = this.appInsightsQueue.slice(Math.min(replyToIdItemIdx + 1, this.appInsightsQueue.length), this.appInsightsQueue.length);
                                    toRemoveItems.forEach(tri => {
                                        this.reportActivityMetric(tri);
                                    });
                                } else {
                                    this.appInsightsQueue.splice(0, 1); // remove self
                                }

                            }
                        }
                    }
                } catch (ex) {

                }
            }

            if (!!this.activitiesStatuses) {
                act.status = (this.activitiesStatuses[act.id] as MessageStatus) || MessageStatus.Sent;
                if (!!!this.activitiesStatuses[act.id]) {
                    this.activitiesStatuses[act.id] = act.status;
                }
                if (act.status != MessageStatus.Read) { //not marked as sent, we should add if not present
                    this.notReadActivities.push(act);
                }

            }
        });

        this.notReadActivities.forEach((nrActivity, actIndex) => { //push if sent -> delivered and do not remove from notReadActivities
            if (!!this.activitiesStatuses[nrActivity.id] && (this.activitiesStatuses[nrActivity.id] as MessageStatus) > MessageStatus.Sent) {
                let foundIndex = activityGroup.activities.findIndex(fi => fi.id == nrActivity.id);
                if (foundIndex > -1) {
                    activityGroup.activities[foundIndex].status = (this.activitiesStatuses[nrActivity.id] as MessageStatus);
                } else {
                    let oldStatus = nrActivity.status;
                    let newStatus = (this.activitiesStatuses[nrActivity.id] as MessageStatus);
                    if (oldStatus != newStatus) { // if same status --- ignore
                        nrActivity.status = (this.activitiesStatuses[nrActivity.id] as MessageStatus);
                        nrActivity.lastConfirmedStatus = (this.activitiesStatuses[nrActivity.id] as MessageStatus);
                        nrActivity.channelData = nrActivity.channelData || {};
                        nrActivity.channelData.isActivityStatusUpdate = true;
                        activityGroup.activities.push(nrActivity);
                    }
                }

                if (nrActivity.status == MessageStatus.Read) {
                    this.notReadActivities.splice(actIndex, 1);
                }
            }
        });

        if (this.conversationStatus_SenderAction && this.conversationStatus_SenderAction.action >= 0) {
            if (this.conversationStatus_SenderAction.action == 1) {
                if (!this.typingIsActive) {
                    activityGroup.activities.push({ type: 'typing', from: { id: '' } });
                    this.typingIsActive = true;
                }
            } else {
                if (!!this.typingIsActive) {
                    activityGroup.activities.push({ type: 'typing', from: { id: '' }, channelData: { isHideTyping: true } });
                    this.typingIsActive = false;
                }
            }
        }

        return Observable.from(activityGroup.activities);
    }

    private webSocketActivity$(): Observable<Activity> {
        return this.checkConnection()
            .flatMap(_ =>
                this.observableWebSocket<ActivityGroup>()
                    .bufferTime(10).filter(buff => buff.length > 0).map(m => {
                        return m.reduce((merged, current) => {
                            return { activities: [...merged.activities, ...current.activities], watermark: current.watermark || merged.watermark };
                        })
                    })
                    // WebSockets can be closed by the server or the browser. In the former case we need to
                    // retrieve a new streamUrl. In the latter case we could first retry with the current streamUrl,
                    // but it's simpler just to always fetch a new one.
                    .retryWhen(error$ => error$.delay(this.getRetryDelay()).mergeMap(error => this.reconnectToConversation()))
            )
            .flatMap(activityGroup => this.observableFromActivityGroup(activityGroup))
    }

    // Returns the delay duration in milliseconds
    private getRetryDelay() {
        return Math.floor(3000 + Math.random() * 12000);
    }

    // Originally we used Observable.webSocket, but it's fairly opionated  and I ended up writing
    // a lot of code to work around their implemention details. Since WebChat is meant to be a reference
    // implementation, I decided roll the below, where the logic is more purposeful. - @billba
    private observableWebSocket<T>() {
        return Observable.create((subscriber: Subscriber<T>) => {
            konsole.log("creating WebSocket", this.streamUrl);
            const ws = new WebSocket(this.streamUrl);
            let sub: Subscription;
            let markMessagesStatusesSub: Subscription;

            ws.onopen = open => {
                konsole.log("WebSocket open", open);
                this.badRequests$.next({ count: 0 });
                // Chrome is pretty bad at noticing when a WebSocket connection is broken.
                // If we periodically ping the server with empty messages, it helps Chrome
                // realize when connection breaks, and close the socket. We then throw an
                // error, and that give us the opportunity to attempt to reconnect.
                sub = Observable.interval(10000).subscribe(_ => { //changed from timeout to hardcoded 10s
                    try {
                        ws.send("")
                    } catch (e) {
                        konsole.log("Ping error", e);
                    }
                });

                if (this.isBotApiEnabled) {
                    markMessagesStatusesSub = Observable.interval(1000).subscribe(_ => { //changed from timeout to hardcoded 10s
                        Observable.from(this.markMessagesStatuses())
                            .catch(() => Observable.empty())
                            .subscribe();
                    });
                }
                try {
                    if (this.conversationStatusWebSocketSubjectSubscription && !this.conversationStatusWebSocketSubjectSubscription.closed) { // must be first thing to do: avoid double handlers
                        this.conversationStatusWebSocketSubjectSubscription.unsubscribe();
                    }

                    if(this.conversationStatusInputSubject) {
                        this.conversationStatusWebSocketSubjectSubscription = this.conversationStatusInputSubject
                            .filter(wsObservable => wsObservable != null)
                            .flatMap(wsObservable => wsObservable)
                            .subscribe(this.handleConversationStatusWebSocket(subscriber));
                    } else {
                        const wsObservable = this.observableConversationStatusWebSocket();
                        this.conversationStatusWebSocketSubject.next(wsObservable);

                        this.conversationStatusWebSocketSubjectSubscription = wsObservable
                            .subscribe(this.handleConversationStatusWebSocket(subscriber));
                    }

                } catch (ex) {
                    console.log("Failed to open ConversationStatusWebSocket", ex);
                }
            }

            ws.onclose = close => {
                konsole.log("WebSocket close", close);
                this.badRequests$.next({ count: 100 }); // just to set offline status

                if (sub) sub.unsubscribe();
                if (markMessagesStatusesSub) markMessagesStatusesSub.unsubscribe();

                subscriber.error(close);
            }

            ws.onmessage = message => {
                let unparsedData = message.data;
                if(message.data && typeof message.data === "string" && this.apcUrl && this.apcExternalUrl) {
                    var apcExternalUrl_regex = new RegExp(this.apcExternalUrl, "g");
                    unparsedData = unparsedData.replace(apcExternalUrl_regex, this.apcUrl);
                }
                return message.data && subscriber.next(JSON.parse(unparsedData));
            }

            // This is the 'unsubscribe' method, which is called when this observable is disposed.
            // When the WebSocket closes itself, we throw an error, and this function is eventually called.
            // When the observable is closed first (e.g. when tearing down a WebChat instance) then
            // we need to manually close the WebSocket.
            return () => {
                if (ws.readyState === 0 || ws.readyState === 1) {
                    ws.close();
                };

                if (this.conversationStatusWebSocket) { // the status doesn't matter. we need to close until master websocket reopens
                    this.conversationStatusWebSocket.close();
                }
            }
        }) as Observable<T>
    }

    private handleConversationStatusWebSocket = subscriber => (message: { messageType: number, data: string }) => {
        let respData: any = {};
        try {
            respData = JSON.parse(message.data);
        } catch (ex) {
            console.log(ex);
            return;
        }

        switch (message.messageType) {
            case 1: {
                if (!!respData && (!respData.routedConversationId || respData.routedConversationId == this.conversationId) && (!respData.conversationId || respData.conversationId == this.conversationId)) {
                    this.updateConversationStatus(respData);
                    subscriber.next({ activities: [] } as any); // triggered to enter this.observableFromActivityGroup(activityGroup)
                }
                break;
            }
            case 2: {
                if (!!respData && (!respData.routedConversationId || respData.routedConversationId == this.conversationId)) {
                    this.updateConversationStatus(respData);
                    subscriber.next({ activities: [] } as any); // triggered to enter this.observableFromActivityGroup(activityGroup)
                }
                break;
            }
        }
    }

    private observableConversationStatusWebSocket(): ReplaySubject<{ messageType: number, data: string }> {

            // let getConversationsUrl = `${this.druidBotApiUrl.replace("https", "wss").replace("http", "ws")}/stream?token=${this.token}&conversationid=${this.conversationId}`;

            let replaySubject = new ReplaySubject<{ messageType: number, data: string }>();

            if (this.conversationStatusWebSocket) {
                this.conversationStatusWebSocket.close();
            }
            this.conversationStatusWebSocket = new ReconnectingWebSocket(() => {
                return `${this.druidBotApiUrl.replace("https", "wss").replace("http", "ws")}/stream?token=${this.token}&conversationid=${this.conversationId}`;
            });
            let sub: Subscription;

            this.conversationStatusWebSocket.onopen = open => {
                this.conversationStatusWebSocket.send("ping");
                sub = Observable.interval(10000).subscribe(_ => {
                    try {
                        this.conversationStatusWebSocket.send("ping");
                    } catch (e) {

                    }
                });
            }

            this.conversationStatusWebSocket.addEventListener('close', (close) => {
                // ws.reconnect();

                if (sub) sub.unsubscribe();
            });

            this.conversationStatusWebSocket.onmessage = message => message.data && replaySubject.next(JSON.parse(message.data));

            return replaySubject;
    }


    private reconnectToConversation() {
        return this.checkConnection(true)
            .flatMap(_ =>
                Observable.ajax({
                    method: "GET",
                    url: `${this.domain}/conversations/${this.conversationId}?watermark=${this.watermark}`,
                    timeout,
                    withCredentials: this.withCredentials,
                    headers: {
                        "Accept": "application/json",
                        ...this.commonHeaders()
                    }
                })
                    .do(result => {
                        if (!this.secret)
                            this.token = result.response.token;
                        const dateNow = moment().utc();
                        if(this.serverTimestamp && dateNow.diff(this.serverTimestamp, "minutes") > 5) { // when reconnecting (because of closed WS by Chrome tab inactivity lag), if diff greater than 5 mins, change the serverTimestamp (no server call to retrive fresh timestamp)
                            this.serverTimestamp = dateNow;
                        }

                        this.streamUrl = result.response.streamUrl;
                    })
                    .map(_ => null)
                    .retryWhen(error$ => error$
                        .mergeMap(error => {
                            if (error.status === 403) {
                                // token has expired. We can't recover from this here, but the embedding
                                // website might eventually call reconnect() with a new token and streamUrl.
                                this.expiredToken();
                            } else if (error.status === 404) {
                                return Observable.throw(errorConversationEnded);
                            }

                            return Observable.of(error);
                        })
                        .delay(timeout)
                        .take(retries)
                    )
            )
    }

    private commonHeaders() {
        return {
            "Authorization": `Bearer ${this.token}`,
            "x-ms-bot-agent": this._botAgent
        };
    }

    private getBotAgent(customAgent: string = ''): string {
        let clientAgent = 'directlinejs'

        if (customAgent) {
            clientAgent += `; ${customAgent}`
        }

        return `${DIRECT_LINE_VERSION} (${clientAgent})`;
    }

    public sendAjaxRequest(options: AjaxRequest): Observable<AjaxResponse> {

        let mergedOptions = {
            ...{ timeout,
                 withCredentials: this.withCredentials
               },
            ...options
        };
        let mergedHeadersOptions = {
            ...{ "Accept": "application/json",
                'Content-Type': 'application/json',
                 ...this.commonHeaders()},
            ...options.headers
            };
        mergedOptions.headers = mergedHeadersOptions;

        return Observable.ajax(options);
    }

    // Workarround for AWS connect -> remove when not used!!!
    addAWSConnection(wsUrl: string, connectionToken: string) {
        this.AWSConnectionConnectionToken = connectionToken;
        this.AWSConnectionWebSocket = new ReconnectingWebSocket(wsUrl);
        let sub: Subscription = null;
        this.AWSConnectionWebSocket.onopen = open => {
            this.AWSConnectionWebSocket.send('{topic: "aws/heartbeat"}');
            this.AWSConnectionWebSocket.send('{"topic":"aws/subscribe","content":{"topics":["aws/chat"]}}')

            sub = Observable.interval(10000).subscribe(_ => {
                try {
                    this.AWSConnectionWebSocket.send('{topic: "aws/heartbeat"}');
                } catch (e) {

                }
            });
        }

        this.AWSConnectionWebSocket.addEventListener('close', (close) => {
            sub && sub.unsubscribe();
        });

        this.AWSConnectionWebSocket.onmessage = message => {

            var respMessage = JSON.parse(message.data);
            if (respMessage.topic == "aws/chat") {
                var respMessageContent = JSON.parse(respMessage.content);
                if (respMessageContent.Type == "MESSAGE" && (respMessageContent.ParticipantRole == "AGENT" || respMessageContent.ParticipantRole == "SYSTEM")) {
                    this.postActivity({
                        text: respMessageContent.Content,
                        type: 'message',
                        from: {
                            id: this.initiatorBotId || "aws-bot",
                            name: "Agent"
                        }
                    }).subscribe();
                }

                if(respMessageContent.Type == "EVENT" && respMessageContent.ContentType == "application/vnd.amazonaws.connect.event.participant.left") {
                    this.postActivity({
                        text: 'disconnect',
                        type: 'message',
                        from: {
                            id: this.initiatorUserId || "anonymous",
                            // name: "Agent"
                        }
                    }).subscribe();
                    this.AWSConnectionWebSocket && this.AWSConnectionWebSocket.close();
                    this.AWSConnectionConnectionToken = null;
                }
            }

            // message.data;
        };

        return this.AWSConnectionWebSocket;
    }
}
