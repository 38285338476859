import { useContext, useEffect, useMemo } from "react";
import { WebChatOptions, webChatDefaultOptions as defaultOptions, webChatFullScreenDefaultOptions, WebChatEventHandlerType } from "../WebChatOptions";
import * as merge from 'deepmerge';
import DruidWebchatContext, { DruidWebchatContextType } from "../WebChatContext";
import { unstable_batchedUpdates } from 'react-dom';
import pRetry from 'p-retry';
import { druidLanguageComparison } from '../logics/druidBusinessLogic';
import { getCookie } from "../utils/cookiesUtils";
import simpleUpdateIn from "simple-update-in";
import { isPlainObject } from 'is-plain-object';
import { DruidContext } from "./useWebChatReducer";
import { mergeChatOptions, visitMergedChatOptions } from "../utils/mergeChatOptions";

export const useLoadConfiguration = ({ contextValue, initialOptions }: { contextValue: DruidContext, initialOptions: WebChatOptions }) => {
  // const [druidWebChatState, druidWebChatDispatch] = useContext<DruidWebchatContextType>(DruidWebchatContext);
  const [druidWebChatState, druidWebChatDispatch, getState] = contextValue;

  useEffect(() => {
    if (!druidWebChatState.loaded) {
      druidWebChatDispatch({type: 'SET_LOADING'});
      (async function () {

        var loadConfigurationResult: WebChatOptions & { middlewares: any };
        if (initialOptions._loadConfigurationJSON) {
          loadConfigurationResult = initialOptions._loadConfigurationJSON;
        } else {
          loadConfigurationResult = await pRetry(async () => {
            const loadConfigurationCallResult = await fetch(`${druidWebChatState.mergedChatOptions.baseUrl}/api/services/app/Bot/LoadConfiguration?botId=${druidWebChatState.mergedChatOptions.botId}`);
            if (loadConfigurationCallResult?.status >= 200 && loadConfigurationCallResult?.status < 300) {
              return loadConfigurationCallResult.json();
            } else {
              return null;
            }
          },
            {
              retries: 4,
              // onFailedAttempt: (error) => {
              //   if(error.retriesLeft == 0) {
              //       throw new pRetry.AbortError(error.message);
              //   }
              // }
            }).catch(ex => {
              druidWebChatDispatch({
                type: 'HIDE_CONTAINERELEMENT',
                payload: true
              });
            });
        }

        druidWebChatDispatch({type: 'SET_LOADCONFIGURATIONRESULT', payload: loadConfigurationResult});

        if (loadConfigurationResult) {
          unstable_batchedUpdates(async () => {
            await babelTransform_WebChatEventHandlers(loadConfigurationResult);
            // if(loadConfigurationResult.transformLoadConfigurationResult) {
            //   loadConfigurationResult.transformLoadConfigurationResult(loadConfigurationResult);
            // }

            // druidWebChatState.mergedChatOptions -> used for action type: 'RESET'. keeps currently set mergedChatOptions with priority
            const nonDefaults_mergedChatOptions = merge.all<WebChatOptions>([loadConfigurationResult, initialOptions, getState().mergedChatOptions], {
              customMerge: (key) => {
                if (key === 'containerElement') {
                  return (a, b) => initialOptions[key];
                }
                if (key === 'directLine') {
                  return (a, b) => initialOptions[key];
                }
              },
              isMergeableObject: isPlainObject
            });

            customMergeWebChatEventHandlers(nonDefaults_mergedChatOptions, loadConfigurationResult, initialOptions);
            createMergedChatOptionsDefaults(nonDefaults_mergedChatOptions);
            let mergedChatOptions = merge.all<WebChatOptions>([nonDefaults_mergedChatOptions.isWidget ? defaultOptions : webChatFullScreenDefaultOptions, nonDefaults_mergedChatOptions], {
              customMerge: (key) => {
                if (key === 'containerElement') {
                  return (a, b) => initialOptions[key];
                }
                if (key === 'directLine') {
                  return (a, b) => initialOptions[key];
                }
              },
              isMergeableObject: isPlainObject
            });

            // after all options are merged, override from wideOptions
            if (!mergedChatOptions.isWidget && mergedChatOptions.wideOptions) {
              mergedChatOptions = mergeChatOptions(mergedChatOptions, mergedChatOptions.wideOptions);
            }

            if ((mergedChatOptions.UI?.showUploadButton !== null && mergedChatOptions.UI?.showUploadButton !== undefined) && !!mergedChatOptions.styleOptions) {
              mergedChatOptions.styleOptions!.hideUploadButton = mergedChatOptions.UI?.showUploadButton!;
            }

            await babelTransform_WebChatEventHandlers(mergedChatOptions);

            if (!!mergedChatOptions.middlewares && Object.keys(mergedChatOptions.middlewares).length) {
              const allMiddlewaresKeys = Object.keys(mergedChatOptions.middlewares);
              if (allMiddlewaresKeys.find(mid => mergedChatOptions.middlewares && mergedChatOptions.middlewares[mid])) {
                 Object.keys(mergedChatOptions.middlewares).forEach(async middleware => {
                  try {
                    if (mergedChatOptions.middlewares && mergedChatOptions.middlewares[middleware]) {
                      return druidWebChatDispatch({
                        type: 'SET_MIDDLEWARE_OR_EVENT_HANDLER', payload: { handlerName: middleware, handler: mergedChatOptions.middlewares[middleware], withDecorate: false}
                      });
                    }

                  } catch (ex) {
                    console.error(`Failed to load middleware: ${middleware} with exception: ${ex}`);
                  }
                  
                });
              }


            }
            // mergedChatOptions = visitMergedChatOptions(mergedChatOptions);
            druidWebChatDispatch({
              type: 'SET_MERGEDCHATOPTIONS',
              payload: mergedChatOptions
            });

            // could be moved before visitMergeChatOptions
            druidWebChatDispatch({
              type: 'SET_PRISTINE_MERGEDCHATOPTIONS',
              payload: mergedChatOptions
            });

            druidWebChatDispatch({
              type: 'HIDE_CONTAINERELEMENT',
              payload: mergedChatOptions.isDisabled === true
            });

            if (mergedChatOptions.UI?.position !== 'right') { // if it is not right -> then left
              druidWebChatDispatch({
                type: 'SET_SIDE',
                payload: 'left'
              });
            }

            if (mergedChatOptions.UI?.isVoiceInput === true) { // start with voice mode
              druidWebChatDispatch({
                type: 'SET_IS_VOICEINPUT',
                payload: true
              });
            }

            if (mergedChatOptions.UI?.headerOptions) {
              druidWebChatDispatch({
                type: 'SET_HEADEROPTIONS',
                payload: mergedChatOptions.UI?.headerOptions
              });
            }

            // loadConfigurationResult.languages = [
            //   // {
            //   //   "name": "it",
            //   //   "isDefault": false,
            //   //   "friendlyName": "Italian",
            //   //   "icon": "famfamfam-flags it",
            //   //   "id": 4
            //   // },
            //   {
            //     "name": "ro",
            //     "isDefault": true,
            //     "nativeName": "Romanian",
            //     "icon": "famfamfam-flags ro",
            //     "id": 15
            //   },
            //   // {
            //   //   "name": "en-US",
            //   //   "isDefault": true,
            //   //   "friendlyName": "English",
            //   //   "icon": "famfamfam-flags us",
            //   //   "id": 1
            //   // }
            //   // ,
            //   // {
            //   //   "name": "ar-EG",
            //   //   "isDefault": false,
            //   //   "friendlyName": "Arabic",
            //   //   "icon": "famfamfam-flags us",
            //   //   "id": 5
            //   // }
            // ];
            if (loadConfigurationResult.languages && loadConfigurationResult.languages.length) {
              const selectedLocaleByOptions = getCookie(mergedChatOptions.conversationToken_namePrefix || "" + 'druidConversationLanguage') || mergedChatOptions.locale || window.navigator.language;
              loadConfigurationResult.languages = loadConfigurationResult.languages.map(lg => {
                // selectedLocaleByOptions.toLocaleLowerCase().startsWith(lg.name.toLocaleLowerCase())
                if (druidLanguageComparison(selectedLocaleByOptions, lg.name)) {
                  lg.isSelected = true;
                }
                return lg;
              });

              if (!loadConfigurationResult.languages.find(lg => lg.isSelected)) {
                loadConfigurationResult.languages = loadConfigurationResult.languages.map(lg => ({
                  ...lg,
                  isSelected: lg.isDefault
                }));
              }

              const selectedLanguage = loadConfigurationResult.languages.find(lg => lg.isSelected);

              druidWebChatDispatch({
                type: 'SET_BOTLANGUAGES',
                payload: loadConfigurationResult.languages
              });

              druidWebChatDispatch({
                type: 'SET_SELECTEDLANGUAGE',
                payload: selectedLanguage!.name
              });
            }

            druidWebChatDispatch({ // set_loaded = false -> isDisabled and stops here
              type: 'SET_LOADED',
              payload: !(mergedChatOptions.isDisabled === true)
            });

          });
        }

      })();
    }
  }, [initialOptions, druidWebChatState.mergedChatOptions.baseUrl, druidWebChatDispatch, defaultOptions, webChatFullScreenDefaultOptions, druidWebChatState.mergedChatOptions.botId, druidWebChatState.loaded]);

  //druidWebChatState.mergedChatOptions.loaded used to reload on 'RESET' action
}

const customMergeWebChatEventHandlers = (mergedChatOptions: WebChatOptions, loadConfigurationResult: WebChatOptions, initialOptions: WebChatOptions) => {
  // if (loadConfigurationResult.webChatEventHandler && initialOptions.webChatEventHandler) {
  //   mergedChatOptions.webChatEventHandler = (...args: Parameters<WebChatEventHandlerType>) => {
  //     loadConfigurationResult.webChatEventHandler && loadConfigurationResult.webChatEventHandler instanceof Function && loadConfigurationResult.webChatEventHandler(...args);
  //     initialOptions.webChatEventHandler && loadConfigurationResult.webChatEventHandler instanceof Function && initialOptions.webChatEventHandler(...args);
  //   }
  // }
}

const babelTransform_WebChatEventHandlers = async (container: WebChatOptions) => {
  if (container.webChatEventHandler && typeof container.webChatEventHandler === "string") {
    const babel = await import(
      /* webpackChunkName: "babel_standalone" */
      '@babel/standalone'
    );
    try {
      const babelEval = eval(babel.transform((container.webChatEventHandler! as any as string), { presets: ['es2015', 'react', 'stage-3'] }).code || "");
      container.webChatEventHandler =
        (...args) => {
          try {
            babelEval(...args);
          } catch (ex) {
            console.error(`Failed to execute webChatEventHandler: with exception: ${ex}`);
          }
        };
    } catch (ex) {
      console.error(`Failed to load webChatEventHandler: with exception: ${ex}`);
    }
  }

  //Nothing wrong. just don't need it for the moment
  // if (container.transformLoadConfigurationResult && typeof container.transformLoadConfigurationResult === "string") {
  //   const babel = await import(
  //     /* webpackChunkName: "babel_standalone" */
  //     '@babel/standalone'
  //   );
  //   try {
  //     const babelEval = eval(babel.transform((container.transformLoadConfigurationResult! as any as string), { presets: ['es2015', 'react', 'stage-3'] }).code || "");
  //     container.transformLoadConfigurationResult = babelEval;
  //   } catch (ex) {
  //     console.error(`Failed to load transformLoadConfigurationResult: with exception: ${ex}`);
  //   }
  // }
  

}
const createMergedChatOptionsDefaults = (mergedChatOptions: WebChatOptions) => {

  const paddingRegularDivided = mergedChatOptions.styleOptions?.paddingRegular && (mergedChatOptions.styleOptions?.paddingRegular! / 2);
  // styleOptions
  if (!mergedChatOptions.styleOptions) {
    mergedChatOptions.styleOptions = {};
  }

  if (mergedChatOptions.styleOptions.bubbleNubSize !== undefined && (mergedChatOptions.styleOptions.bubbleFromUserNubSize === null || mergedChatOptions.styleOptions.bubbleFromUserNubSize === undefined)) {
    mergedChatOptions.styleOptions.bubbleFromUserNubSize = mergedChatOptions.styleOptions.bubbleNubSize;
  }

  if (mergedChatOptions.styleOptions.bubbleBorderRadius !== undefined && (mergedChatOptions.styleOptions.bubbleFromUserBorderRadius === null || mergedChatOptions.styleOptions.bubbleFromUserBorderRadius === undefined)) {
    mergedChatOptions.styleOptions.bubbleFromUserBorderRadius = mergedChatOptions.styleOptions.bubbleBorderRadius;
  }
  if (mergedChatOptions.styleOptions.suggestedActionBorderRadius === undefined) {
    mergedChatOptions.styleOptions = simpleUpdateIn(mergedChatOptions.styleOptions, ['suggestedActionBorderRadius'], () => mergedChatOptions.UI?.buttonsBorderRadius || paddingRegularDivided);
  }

  // if (mergedChatOptions.styleOptions?.suggestedActionBackground === undefined) {
  //   mergedChatOptions.styleOptions = simpleUpdateIn(mergedChatOptions.styleOptions, ['suggestedActionBackground'], () => mergedChatOptions.UI?.buttonsBackgroundColor);
  // }

  // if (mergedChatOptions.styleOptions?.suggestedActionBorderColor === undefined) {
  //   mergedChatOptions.styleOptions = simpleUpdateIn(mergedChatOptions.styleOptions, ['suggestedActionBorderColor'], () => mergedChatOptions.UI?.buttonsBorderColor || mergedChatOptions.styleOptions?.accent);
  // }

  // if (mergedChatOptions.styleOptions?.suggestedActionTextColor === undefined) {
  //   mergedChatOptions.styleOptions = simpleUpdateIn(mergedChatOptions.styleOptions, ['suggestedActionTextColor'], () => mergedChatOptions.UI?.buttonsTextColor || mergedChatOptions.styleOptions?.accent);
  // }



  // adaptiveCardsHostConfig
  if (!mergedChatOptions.adaptiveCardsHostConfig) {
    mergedChatOptions.adaptiveCardsHostConfig = {};
  }

  var spacingConfig = mergedChatOptions.adaptiveCardsHostConfig?.spacing || {};
  if (!spacingConfig.padding) {
    spacingConfig = simpleUpdateIn(spacingConfig, ['padding'], () => mergedChatOptions.styleOptions?.paddingRegular);
  }
  mergedChatOptions.adaptiveCardsHostConfig.spacing = spacingConfig;


  // // UI options
  // if (!mergedChatOptions.UI) {
  //   mergedChatOptions.UI = {};
  // }

  // if (mergedChatOptions.UI.buttonsPadding === undefined || mergedChatOptions.UI.buttonsPadding === null) {
  //   mergedChatOptions.UI = simpleUpdateIn(mergedChatOptions.UI, ['buttonsPadding'], () => paddingRegularDivided);
  // }

  // if (mergedChatOptions.UI?.buttonsBorderRadius === undefined || mergedChatOptions.UI?.buttonsBorderRadius === null) {
  //   mergedChatOptions.UI = simpleUpdateIn(mergedChatOptions.UI, ['buttonsBorderRadius'], () => paddingRegularDivided);
  // }

  // if (mergedChatOptions.UI?.buttonsBorderColor === undefined || mergedChatOptions.UI?.buttonsBorderColor === null) {
  //   mergedChatOptions.UI = simpleUpdateIn(mergedChatOptions.UI, ['buttonsBorderColor'], () => mergedChatOptions.styleOptions?.accent);
  // }

  // if (mergedChatOptions.UI?.buttonsTextColor === undefined || mergedChatOptions.UI?.buttonsTextColor === null) {
  //   mergedChatOptions.UI = simpleUpdateIn(mergedChatOptions.UI, ['buttonsTextColor'], () => mergedChatOptions.styleOptions?.accent);
  // }
  // if (mergedChatOptions.UI?.buttonsBackgroundColor === undefined || mergedChatOptions.UI?.buttonsBackgroundColor === null) {
  //   mergedChatOptions.UI = simpleUpdateIn(mergedChatOptions.UI, ['buttonsBackgroundColor'], () => mergedChatOptions.styleOptions?.accent);
  // }

}